export const topPicks = [
  {
    id: 1,
    title: 'shawarma',
    price: '$850',
    img: 'https://res.cloudinary.com/ehizeex-shop/image/upload/v1672323737/NetflixApp/chiken_shawarma_c0ysna.jpg'
  },
  {
    id: 2,
    title: 'fruite juice',
    price: '$900',
    img: 'https://res.cloudinary.com/ehizeex-shop/image/upload/v1672329939/NetflixApp/fruite_juice_du7uqy.jpg',
  },
  {
    id: 3,
    title: 'yogurt parfaite',
    price: '$450',
    img: "https://res.cloudinary.com/ehizeex-shop/image/upload/v1672329944/NetflixApp/yogurt_parfait_kt8b5f.jpg"
  },
  {
    id: 4,
    title: 'orange juice',
    price: '$420',
    img: 'https://res.cloudinary.com/ehizeex-shop/image/upload/v1672329938/NetflixApp/orangejuice_s4mdbi.webp'
  },
  {
    id: 5,
    title: 'egg & plantain',
    price: '$700',
    img: 'https://res.cloudinary.com/ehizeex-shop/image/upload/v1672331290/NetflixApp/edd_and_plantain_rey7l4.jpg',
  },
  {
    id: 6,
    title: 'tilapia fish',
    price: '$900',
    img: 'https://res.cloudinary.com/ehizeex-shop/image/upload/v1672331305/NetflixApp/tilapia_fish_g0ozkn.jpg'
  },
  {
    id: 7,
    title: 'jollof rice',
    price: '$150',
    img: 'https://res.cloudinary.com/ehizeex-shop/image/upload/v1672332323/NetflixApp/jollof_rice_gbzosf.webp',
  },
  {
    id: 8,
    title: 'chiken burger',
    price: '$300',
    img: 'https://res.cloudinary.com/ehizeex-shop/image/upload/v1672332323/NetflixApp/chiken_burger_ncsmeh.webp',
  },
  {
    id: 9,
    title: 'egg and plantain',
    price: '$500',
    img: 'https://res.cloudinary.com/ehizeex-shop/image/upload/v1672331290/NetflixApp/edd_and_plantain_rey7l4.jpg',
  },
  {
    id: 10,
    title: 'African Salad',
    price: '$200',
    img: 'https://res.cloudinary.com/ehizeex-shop/image/upload/v1672323870/NetflixApp/African_Salad_s7vgxn.png',
  },
  {
    id: 11,
    title: 'chinese pizza',
    price: '$900',
    img: 'https://res.cloudinary.com/ehizeex-shop/image/upload/v1672324456/NetflixApp/chinese_pizza_kgnbo1.jpg',
  },
];


export const mealData = [
  {
    id: 5,
    name: 'cheeseless pizza',
    category: 'pizza',
    image:
      'https://res.cloudinary.com/ehizeex-shop/image/upload/v1672324456/NetflixApp/cheeseless_pizza_bkuecz.jpg',
    price: '$200',
  },
  {
    id: 6,
    name: 'Supreme Pizza',
    category: 'pizza',
    image:
      'https://res.cloudinary.com/ehizeex-shop/image/upload/v1672324455/NetflixApp/Supreme_Pizza_jtvkj8.jpg',
    price: '$300',
  },
  {
    id: 7,
    name: 'pepperoni pizza',
    category: 'pizza',
    image:
      'https://res.cloudinary.com/ehizeex-shop/image/upload/v1672324456/NetflixApp/pepperoni_pizza_axwdat.jpg',
    price: '$100',
  },
  {
    id: 8,
    name: 'Cheese Pizza',
    category: 'pizza',
    image:
      'https://res.cloudinary.com/ehizeex-shop/image/upload/v1672324456/NetflixApp/chinese_pizza_kgnbo1.jpg',
    price: '$140',
  },
  {
    id: 8,
    name: 'African Salad',
    category: 'Salad',
    image:
      'https://res.cloudinary.com/ehizeex-shop/image/upload/v1672323870/NetflixApp/African_Salad_s7vgxn.png',
    price: '$140',
  },
  {
    id: 9,
    name: 'Kale Salad',
    category: 'salad',
    image:
      'https://res.cloudinary.com/ehizeex-shop/image/upload/v1672323860/NetflixApp/kale_salad_ogune3.jpg',
    price: '$300',
  },
  {
    id: 10,
    name: 'Ceasar Salad',
    category: 'salad',
    image:
      'https://res.cloudinary.com/ehizeex-shop/image/upload/v1672323859/NetflixApp/caesar-salad-with-tuna-30216-1_noflfd.jpg',
    price: '$300',
  },
  {
    id: 11,
    name: 'Loaded Salad',
    category: 'salad',
    image:
      'https://res.cloudinary.com/ehizeex-shop/image/upload/v1672323860/NetflixApp/loaded_salad_qq5jup.jpg',
    price: '$200',
  },
  {
    id: 12,
    name: 'chiken_shawarma',
    category: 'chiken',
    image:
      'https://res.cloudinary.com/ehizeex-shop/image/upload/v1672323737/NetflixApp/chiken_shawarma_c0ysna.jpg',
    price: '$100',
  },
  {
    id: 13,
    name: 'Wings',
    category: 'chicken',
    image:
      'https://res.cloudinary.com/ehizeex-shop/image/upload/v1672322922/NetflixApp/chicken-wings_lv3zc9.jpg',
    price: '$100',
  },
  {
    id: 14,
    name: 'Roasted Chicken',
    category: 'chicken',
    image:
      'https://res.cloudinary.com/ehizeex-shop/image/upload/v1672322922/NetflixApp/roast-chicken_nrc6r8.jpg',
    price: '$300',
  },
  {
    id: 15,
    name: 'Chicken Tenders',
    category: 'chicken',
    image:
      'https://res.cloudinary.com/ehizeex-shop/image/upload/v1672322923/NetflixApp/chicken_tenders_quel6y.jpg',
    price: '$100',
  },

];



  export const categories = [
    {
      id: 1,
      name: 'Burger',
      image:
         'https://res.cloudinary.com/ehizeex-shop/image/upload/v1672070207/NetflixApp/burger_logo_hmpqua.png'
    },
    {
      id: 2,
      name: 'veg',
      image:
        'https://res.cloudinary.com/ehizeex-shop/image/upload/v1672070207/NetflixApp/veg_logo_jvm4qn.png'
    },
    {
      id: 3,
      name: 'Pizza',
      image:
        'https://res.cloudinary.com/ehizeex-shop/image/upload/v1672070207/NetflixApp/pizza_logo_dqdqds.png',
    },
    {
      id: 4,
      name: 'Wings',
      image:
        'https://res.cloudinary.com/ehizeex-shop/image/upload/v1672070208/NetflixApp/wings_logo_hrn8zl.png'
    },

    {
      id: 5,
      name: 'Best Overall',
      image:
        'https://res.cloudinary.com/ehizeex-shop/image/upload/v1672070207/NetflixApp/fastfood_logo_v80nbk.png'
    },
    {
      id: 6,
      name: 'sharwama',
      image:
        'https://res.cloudinary.com/ehizeex-shop/image/upload/v1672070207/NetflixApp/sharwama_logo_st4vg8.png'
    },
    {
      id: 7,
      name: 'resottoa',
      image:
        'https://res.cloudinary.com/ehizeex-shop/image/upload/v1672176749/NetflixApp/resotto_logo_uywrr1.png'
    },
    {
      id: 8,
      name: 'cake',
      image:
        'https://res.cloudinary.com/ehizeex-shop/image/upload/v1672176749/NetflixApp/cake_edpoec.png'
    },
    {
      id: 9,
      name: 'chiken',
      image:
        'https://res.cloudinary.com/ehizeex-shop/image/upload/v1672176749/NetflixApp/chiken_logo_ahl2ca.png'
    },
    {
      id: 10,
      name: 'ice cream',
      image:
        'https://res.cloudinary.com/ehizeex-shop/image/upload/v1672176749/NetflixApp/ice_cream_logo_hcqcaa.png'
    },
    {
      id: 11,
      name: 'beef',
      image:
       'https://res.cloudinary.com/ehizeex-shop/image/upload/v1672176749/NetflixApp/beef_logo_adnidz.png'
    },
    {
      id: 12,
      name: 'apple pie',
      image:
        'https://res.cloudinary.com/ehizeex-shop/image/upload/v1672176749/NetflixApp/appple_pie_yxfxpm.png'
    },
  ];